import { SyntheticEvent } from 'react';

import { usePickerDebounced } from '~/src/common/hooks/use-picker-debounced';

type UsePickerDebouncedPayload = {
  cachedQuantity: number;
  onUpdate: (quantity: number) => void;
  waitingDuration?: number;
};

const DEFAULT_DURATION = 500;

type UsePickerPayload = {
  isEnabled: boolean;
  onDisabledClick: (quantity: number, increment: number) => void;
  availableQuantity: number;
} & UsePickerDebouncedPayload;

/**
 * Picker de produit connecté au cache de RQ
 * Quand on modifie la quantité interne on appelle la callback de mise a jour avec un debounce
 * Quand la quantité est modifiée depuis l'exterieur on n'appelle pas cette callback
 * @param cachedQuantity number : quantité extérieure (provenant du cache)
 * @param onUpdate Callback de mise a jour
 * @param waitingDuration number durée du debounce
 * @param isEnabled Indique si le picker est actif ou non
 * @param onDisabledClick Callback de click sur le picker désactivé
 * @returns [internalCount, updateAndDebounce] l'état du compteur et sa fonction pour le mettre a jour
 */
export const usePicker = ({
  cachedQuantity,
  onUpdate,
  waitingDuration = DEFAULT_DURATION,
  isEnabled,
  onDisabledClick,
  availableQuantity,
}: UsePickerPayload) => {
  const [debouncedQuantity, setDebouncedQuantity] = usePickerDebounced({
    cachedQuantity,
    onUpdate,
    waitingDuration,
  });

  const handleQuantityChange = (currentQuantity: number, increment: number, e?: SyntheticEvent) => {
    if (e != null) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (isEnabled) setDebouncedQuantity(currentQuantity + increment);
    else {
      onDisabledClick(currentQuantity, increment);
    }
  };

  const isMaxQuantityReached = debouncedQuantity >= availableQuantity;

  return {
    count: debouncedQuantity,
    handleQuantityChange,
    isMaxQuantityReached,
  };
};
