import styled, { css } from 'styled-components';

import { BOOKMARK_PRODUCT_BUTTON_WIDTH } from '~/src/common/components/BookmarkProductButton/layout';
import Button from '~/src/common/components/Button';
import { ButtonContent } from '~/src/common/components/Button/layout';
import { productCardTagStyle } from '~/src/common/components/GridCard/layout';
import OriginSVGFlag from '~/src/common/components/OriginFlag';
import type colors from '~/src/common/themes/main/colors';

export const PromoTag = styled.div<{
  $backgroundColor: keyof typeof colors;
  $color: keyof typeof colors;
}>`
  ${productCardTagStyle};

  background-color: ${({ theme, $backgroundColor }) => theme.palette.common[$backgroundColor]};
  color: ${({ theme, $color }) => theme.palette.common[$color]};

  &::first-letter {
    text-transform: uppercase;
  }

  /* 2 * 8 est le padding de la carte produit
   8 est la marge avec le bouton de bookmark */
  max-width: calc(100% - ${BOOKMARK_PRODUCT_BUTTON_WIDTH + 2 * 8 + 8}px);
`;

export const CustomTag = styled.div`
  ${productCardTagStyle};

  background-color: ${({ theme }) => theme.palette.common.LIGHT_GREEN};
  color: ${({ theme }) => theme.palette.primary};

  /* 2 * 8 est le padding de la carte produit
   8 est la marge avec le bouton de bookmark */
  max-width: calc(100% - ${BOOKMARK_PRODUCT_BUTTON_WIDTH + 2 * 8 + 8}px);
`;

export const OriginAndLabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.img`
  width: 24px;
  height: 24px;
`;

export const LabelsContainer = styled.div`
  display: flex;
  align-items: center;

  & img {
    width: 24px;
    min-width: 24px;
  }

  & img:not(:last-child) {
    margin-right: 4px !important;
  }
`;

export const AdditionalLabelCounter = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  min-width: 24px;
  height: 24px;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.common.WHITE};
  color: ${({ theme }) => theme.palette.common.PRIMARY};
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

export const BottomInformationContainer = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.palette.common.WHITE};

  & > span {
    display: flex;
    align-items: center;

    &:first-child {
      font-size: 12px;
      line-height: 16px;
    }

    & span:first-child {
      margin-right: 5px;
    }

    & span:last-child {
      position: relative;
      top: 1px;
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

export const OriginFlag = styled(OriginSVGFlag)`
  margin-right: 4px;
  border-radius: 5px;
  width: 12px;
  height: 12px;
`;

export const Origin = styled.div`
  display: flex;

  & > p {
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    color: ${({ theme }) => theme.palette.common.WHITE};
    margin: 0;
  }
`;

export const ProductPriceGranularity = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 48px);
  overflow: hidden;
  margin: 0;

  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.common.DISABLED};

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const PickerQuantity = styled.span`
  width: 32px;
  height: 40px;
  padding: 12px 0;

  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  color: ${({ theme }) => theme.palette.common.WHITE};
  background-color: ${({ theme }) => theme.palette.common.PRIMARY};
`;

const updateQuantityButtonStyle = css`
  height: 40px;
  min-width: 32px;
  padding: 0 4px;

  &[disabled],
  &[disabled]:hover {
    cursor: not-allowed;
    background: ${({ theme }) => theme.palette.common.DISABLED};
    color: ${({ theme }) => theme.palette.common.WHITE};
    border-color: ${({ theme }) => theme.palette.common.DISABLED};
  }

  padding-right: 0;
  padding-left: 0;
`;

export const AddQuantityButton = styled(Button)`
  ${updateQuantityButtonStyle};
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

export const RemoveQuantityButton = styled(Button)`
  ${updateQuantityButtonStyle};

  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`;

export const PickerButtonsWrapper = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 40px;
  overflow: hidden;
  border-radius: 8px;
  transition: width 0.15s;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          width: 96px;
          overflow: auto;
          z-index: 13;
        `
      : css`
          width: 33px;
          transition: width 0.15s;
        `}
`;

export const PickerButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  border-radius: 8px;

  width: 96px;
  z-index: 5;
`;

export const FoldedPickerButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 5;

  width: 40px;
  min-width: unset;
  height: 40px;
  padding-right: 0;
  padding-left: 0;

  & > div > span {
    line-height: 16px;
  }

  & ${ButtonContent} {
    line-height: 16px;
    font-size: 16px;
  }
`;

export const BottomSectionInformationContainer = styled.div`
  width: 100%;
`;
