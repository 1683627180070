import { memo, useContext, useEffect, useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { useNthPromoTooltip } from '~/src/common/components/GridCard/ProductCard/AvailableProductCard/NthPromoTooltip/hooks';
import PickerButtons from '~/src/common/components/GridCard/ProductCard/AvailableProductCard/PickerButtons';
import { ProductCardContext } from '~/src/common/components/GridCard/ProductCard/ProductCardContext';
import { usePicker } from '~/src/common/hooks/use-picker';
import { useStable } from '~/src/common/hooks/use-stable';
import I18n from '~/src/common/services/I18n';
import { sendQuantityUpdateEvents } from '~/src/common/utils/cart';
import { noop } from '~/src/common/utils/function';

import * as S from './layout';

type PickerDebouncedProps = {
  cachedQuantity: number;
  availableQuantity: number;
  onDisabledClick: (quantity: number, increment: number) => void;
  enabled?: boolean;
  packSize?: number;
};

const AUTO_CLOSE_DELAY = 3000;
const Picker = ({
  onDisabledClick,
  cachedQuantity,
  availableQuantity,
  enabled = true,
  packSize = 1,
}: PickerDebouncedProps) => {
  // Etat du picker + fermeture automatique
  const [isOpen, setIsOpen] = useState(false);
  const [pickerQuantity, setPickerQuantity] = useState(cachedQuantity);

  // Synchronisation cachedQuantity -> pickerQuantity
  useEffect(() => setPickerQuantity(cachedQuantity), [cachedQuantity]);

  // Fermeture automatique du picker après un certain temps
  useEffect(() => {
    const timer = setTimeout(() => setIsOpen(false), AUTO_CLOSE_DELAY);
    return () => clearTimeout(timer);
  }, [isOpen, pickerQuantity]);

  // Récupération du produit, de l'utils de mise à jour du panier et des data analytics
  const { product, onQuantityChange, analyticsData } = useContext(ProductCardContext);

  const {
    isVisible: isTooltipVisible,
    triggerVisible: triggerTooltip,
    hide: hideTooltip,
  } = useNthPromoTooltip({
    nthQuantity: product?.promo?.conditions?.nthQuantity,
    availableQuantity: product?.availableQuantity,
    cartQuantity: pickerQuantity,
  });

  // Wrapper stable autour du submit pour intercepter les appels inutiles
  const lastSubmittedQty = useRef(-1);
  const submitPickerQuantity = useStable(() => {
    if (pickerQuantity === cachedQuantity) return;

    // Dirty trick pour empêcher le 2ème appel à onQuantityChange fait automatiquement au unmount du picker lorsque le premier est en erreur.
    // Ce qui provoque également l'affichage d'une deuxième modale d'erreur par dessus la première.
    // L'idéal aurait été pouvoir vérifier si la quantité dispo est suffisant pour faire le updateQuantity cependant la valeur de available quantity
    // récupéré dans la callback onQuantityChange n'est pas à jour car celle-ci est memoized lors de l'init des valeurs du contexte.
    if (pickerQuantity === lastSubmittedQty.current) return;
    lastSubmittedQty.current = pickerQuantity;
    setTimeout(() => {
      lastSubmittedQty.current = -1;
    }, 1000);

    onQuantityChange(pickerQuantity);
  });

  // Submit auto au unmount et à la fermeture du picker
  useEffect(() => submitPickerQuantity, [submitPickerQuantity]);
  useEffect(() => void (!isOpen && submitPickerQuantity()), [isOpen, submitPickerQuantity]);

  // Gestion des analytics
  const queryClient = useQueryClient();
  const sendAnalytics = (quantity: number) => {
    if (product == null || !enabled) return;
    sendQuantityUpdateEvents({
      queryClient,
      product,
      productEventSource: analyticsData?.eventSource,
      subcategoryName: analyticsData?.subcategoryName,
      CategoryNameFromProduct: analyticsData?.categoryName,
      quantity,
      otherAnalyticsProperties: analyticsData?.otherAnalyticsProperties,
    });
  };

  // On passe par handleQuantityChange quand la quantité est 0 afin d'ouvrir
  // l'overlay de sélection de créneau quand l'utilisateur n'a pas de panier
  const { handleQuantityChange } = usePicker({
    cachedQuantity,
    onUpdate: noop, // Pas besoin, on reçoit la valeur via cachedQuantity
    availableQuantity,
    isEnabled: enabled,
    onDisabledClick,
  });

  // Mise à jour quantité, état d'ouverture, et envoi analytics
  const updatePickerQuantity = (quantity: number) => {
    sendAnalytics(quantity);
    setIsOpen(enabled && quantity > 0);
    if (enabled) setPickerQuantity(quantity);
    if (pickerQuantity === 0) handleQuantityChange(0, quantity);
    if (!enabled && pickerQuantity > 0) onDisabledClick(pickerQuantity, quantity);
  };

  // On utilise le cache afin de ne pas afficher en optimise la valeur sur le bouton replié
  // Cela permet à l'animation de se dérouler et de supplanter le rendu
  const hasQuantity = pickerQuantity > 0;

  return (
    <>
      <PickerButtons
        onClickOutside={() => {
          hideTooltip();
          setIsOpen(false);
        }}
        onQuantityChange={(qty, inc) => updatePickerQuantity(qty + inc)}
        quantity={pickerQuantity}
        availableQuantity={availableQuantity}
        isOpen={isOpen}
        isTooltipVisible={isTooltipVisible}
        onAddQuantity={triggerTooltip}
        onRemoveQuantity={hideTooltip}
        incrementStep={packSize}
      />
      <S.FoldedPickerButton
        icon={hasQuantity ? undefined : 'basket-normal'}
        iconSize={22}
        aria-label={I18n.t('common.add-product')}
        onClick={() => {
          if (enabled) {
            triggerTooltip();
          }

          updatePickerQuantity(pickerQuantity > 0 ? pickerQuantity : packSize);
        }}>
        {hasQuantity ? pickerQuantity : null}
      </S.FoldedPickerButton>
    </>
  );
};

export default memo(Picker);
