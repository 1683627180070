import { TippyProps } from '@tippyjs/react';

import I18n from '~/src/common/services/I18n';
import { formatBatchDiscountNthText } from '~/src/common/utils/prices';
import { Home200ItemsItemProductsItemOneOfPromoConditionsType } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

import * as S from './layout';

export interface Props {
  nthQuantity: number;
  type: Home200ItemsItemProductsItemOneOfPromoConditionsType;
  value: number;
  cartQuantity: number;
  isVisible: boolean;
  children: TippyProps['children'];
}

const NthPromoTooltip = ({
  isVisible,
  nthQuantity,
  type,
  value,
  cartQuantity,
  children,
}: Props) => {
  const remaining = nthQuantity - (cartQuantity % nthQuantity);
  const progression =
    cartQuantity % nthQuantity === 0 ? 100 : ((nthQuantity - remaining) / nthQuantity) * 100;

  const benefitTimes = Math.floor(cartQuantity / nthQuantity);

  return (
    <S.OpacityDrivenTippy
      visible
      $opacity={isVisible ? 1 : 0}
      offset={[32, 18]}
      placement="top-end"
      popperOptions={{
        strategy: 'fixed',
      }}
      content={
        <S.Container>
          <S.RemainingQuantityLabel>
            {cartQuantity % nthQuantity === 0 && cartQuantity > 0 ? (
              I18n.t('price.batch-discount-label.discount-applied')
            ) : (
              <>
                <span>
                  {I18n.t('price.batch-discount-label.one-out-of', {
                    quantity: nthQuantity - remaining,
                    goal: nthQuantity,
                  })}
                </span>
                {`${I18n.t('price.batch-discount-label.for-reward')} ${formatBatchDiscountNthText({
                  nthQuantityOffered: nthQuantity * (benefitTimes + 1),
                  type,
                  value,
                })}`}
              </>
            )}
          </S.RemainingQuantityLabel>
          <S.RewardContainer>
            <S.RewardProgression progression={cartQuantity === 0 ? 0 : progression} />
          </S.RewardContainer>
        </S.Container>
      }>
      {children}
    </S.OpacityDrivenTippy>
  );
};

export default NthPromoTooltip;
