import { BasicProduct } from '~/src/common/components/GridCard/ProductCard/type';
import { ImageFormatIds, ImageParams } from '~/src/common/typings/image';
import { getImageUrlFromFormat } from '~/src/common/utils/cloudinary';

import { Label, LabelsContainer, AdditionalLabelCounter } from './layout';

interface Props {
  labels: BasicProduct['labels'];
  nbMaxDisplayed?: number;
}

const NB_LABELS_TO_DISPLAY = 2;

const Labels = ({ labels, nbMaxDisplayed = NB_LABELS_TO_DISPLAY }: Props) => {
  const areLabelsHidden = labels.length > nbMaxDisplayed;
  const nbLabelsDisplayed = areLabelsHidden ? nbMaxDisplayed - 1 : labels.length;

  return (
    <LabelsContainer>
      {labels.slice(0, nbLabelsDisplayed).map(({ id, label, images }) => {
        const imageUrl = getImageUrlFromFormat(
          images[0],
          ImageFormatIds.Square,
          ImageParams.productLabels,
        );
        return imageUrl ? <Label key={id} src={imageUrl} alt={label} /> : null;
      })}

      {areLabelsHidden ? (
        <AdditionalLabelCounter>+{labels.length - nbLabelsDisplayed}</AdditionalLabelCounter>
      ) : null}
    </LabelsContainer>
  );
};

export default Labels;
