import styled from 'styled-components';

import { BookmarkProductButton as BookmarkProductButtonBase } from '~/src/common/components/BookmarkProductButton';
import { PICKER_SECTION_HEIGHT } from '~/src/common/components/GridCard/layout';

export const BookmarkProductButton = styled(BookmarkProductButtonBase)`
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;

  border-radius: 4px;

  && {
    background-color: ${({ theme }) => theme.palette.common.WHITE};
  }
`;

export const TopSectionContainer = styled.div`
  height: calc(calc(100% - ${PICKER_SECTION_HEIGHT}px) + 0.5px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
