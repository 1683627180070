import DeliveryInfoPanel from '~/src/common/components/DeliveryInfoPanel';

import * as S from './layout';

interface Props {
  hasAddress: boolean;
  isDelivery: boolean;
  isOpen: boolean;
  onOpen: (productId: string | null) => void;
  onUpdateDelivery: () => void;
}

const DeliveryPanel = (props: Props) => {
  return (
    <S.DeliveryInfoLayout
      $isOpen={props.isOpen}
      onMouseLeave={props.isOpen ? () => props.onOpen(null) : undefined}>
      <S.DeliveryInfoContainer $isOpen={props.isOpen}>
        <S.DeliveryInfoContent>
          <DeliveryInfoPanel
            onClose={() => props.onOpen(null)}
            hasAddress={props.hasAddress}
            isDelivery={props.isDelivery}
            onUpdateDelivery={props.onUpdateDelivery}
          />
        </S.DeliveryInfoContent>
      </S.DeliveryInfoContainer>
    </S.DeliveryInfoLayout>
  );
};
export default DeliveryPanel;
