import styled from 'styled-components';

export type ObjectFitTypes = 'fill' | 'contain' | 'cover' | 'scale-down' | 'none';

export const Image = styled.img<{
  $objectFit?: ObjectFitTypes;
  $objectPosition?: string;
}>`
  height: 100%;
  width: 100%;
  min-width: 100%;

  object-fit: ${({ $objectFit = 'cover' }) => $objectFit};
  object-position: ${({ $objectPosition }) => $objectPosition};
`;
