import { createContext } from 'react';

import { UpdateCartProduct } from '~/src/common/hooks/cart/types';
import {
  AddToCartProductEventSource,
  ExtraCartEventProperties,
} from '~/src/common/services/Tracker';
import { noop } from '~/src/common/utils/function';

export type ProductCardContextType = {
  analyticsData: {
    categoryName?: string;
    subcategoryName?: string;
    eventSource: AddToCartProductEventSource;
    otherAnalyticsProperties?: ExtraCartEventProperties;
  };
  product: UpdateCartProduct | undefined;
  onQuantityChange: (quantity: number) => void;
};

export const ProductCardContext = createContext<ProductCardContextType>({
  analyticsData: { eventSource: 'cart' },
  product: undefined,
  onQuantityChange: noop,
});
