import React from 'react';

import { Image, ObjectFitTypes } from './layout';

type Props = Omit<React.ComponentProps<typeof Image>, 'objectFit' | 'objectPosition'> & {
  objectFit?: ObjectFitTypes;
  objectPosition?: string;
};

const ImageObjectFit = ({
  objectFit = 'cover',
  objectPosition = 'center center',
  src,
  ...props
}: Props) => (
  <Image
    src={src}
    {...props}
    $objectFit={objectFit}
    $objectPosition={objectPosition}
    loading="lazy"
  />
);

export default ImageObjectFit;
