import styled from 'styled-components';

import Button from '~/src/common/components/Button';

import { Image as BaseImage } from '../../layout';

export const UnavailableTag = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 0 4px;

  border-radius: 4px;
  color: ${({ theme }) => theme.palette.common.TEXTS};
  background-color: ${({ theme }) => theme.palette.common.WHITE};
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
`;

export const Image = styled(BaseImage)`
  filter: grayscale(1);
`;

export const RestockInformation = styled.p`
  padding: 4px 0;

  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.common.WHITE};
  margin: 0;
`;

export const NoSubstituteProduct = styled.p`
  margin: 4px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.common.TEXTS};
`;

export const SubstituteProductsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.common.PRIMARY};
    cursor: pointer;
  }
`;

export const SubstituteProductButton = styled(Button)`
  height: 40px;
  min-height: 40px;
  min-width: 40px;
`;
