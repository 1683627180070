import styled from 'styled-components';

import { StyledTippy } from '~/src/common/components/Tooltip/layout';
import { media } from '~/src/styles-constants';

export const OpacityDrivenTippy = styled(StyledTippy)<{ $opacity: number }>`
  opacity: ${({ $opacity }) => $opacity};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 136px;
  padding: 3px 0;
  margin-left: -1px;
  margin-right: -1px;

  ${media.S`
    width: 177px;
  `}
`;

export const RemainingQuantityLabel = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  white-space: nowrap;

  & > span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    margin-right: 2px;
  }
`;

export const RewardProgression = styled.div<{ progression: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.common.PROMOTION};
  width: ${({ progression }) => progression}%;
  transition: width 0.4s ease-in-out;
`;

export const RewardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.palette.common.PROMOTION_LIGHT};
  border-radius: 4px;
  overflow: hidden;

  & > *:not(${RewardProgression}) {
    z-index: 10;
  }
`;
