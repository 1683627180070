import { BasicProduct } from '~/src/common/components/GridCard/ProductCard/type';
import { formatPrice, getPriceParts } from '~/src/common/utils/prices';

import * as S from './layout';

interface Props {
  price: BasicProduct['itemPrice'];
  promo?: BasicProduct['promo'];
}

const StyledPrice = ({ price, promo }: Props) => {
  const { integer, fraction, decimal, currency } = getPriceParts(price);

  const isPromo = promo != null;
  const isDestock = isPromo && promo.type === 'DESTOCK';
  const isBatchDiscount = isPromo && promo.mechanism === 'BATCH_DISCOUNT';

  const backgroundColor = isDestock ? 'DESTOCK' : 'PROMOTION';

  const isPromoDisplayed = isPromo && !isBatchDiscount;

  return (
    <S.Container>
      <S.PriceContainer
        $isPromo={isPromoDisplayed}
        $backgroundColor={isPromo ? backgroundColor : 'TRANSPARENT'}
        $color={isDestock ? 'WHITE' : 'TEXTS'}>
        <span>
          {integer}
          {decimal || null}
        </span>
        {fraction ? <S.Decimals>{fraction}</S.Decimals> : null}
        <span>{currency}</span>
      </S.PriceContainer>
      {isPromoDisplayed && promo?.itemOriginalPrice ? (
        <S.OriginalPrice>{formatPrice(promo.itemOriginalPrice)}</S.OriginalPrice>
      ) : null}
    </S.Container>
  );
};

export default StyledPrice;
