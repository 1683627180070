import { useCallback, useEffect, useRef, useState } from 'react';

const AUTO_CLOSE_DELAY = 3000;

interface Props {
  nthQuantity?: number;
  availableQuantity?: number;
  cartQuantity: number;
}

export const useNthPromoTooltip = ({ nthQuantity, availableQuantity = 1, cartQuantity }: Props) => {
  const timeout = useRef<number | null>(null);
  const [isVisible, setVisible] = useState(false);
  const isAvailable = nthQuantity != null;

  const remaining = nthQuantity != null ? nthQuantity - (cartQuantity % nthQuantity) : 0;
  const isPromoReachable = isAvailable ? remaining <= availableQuantity - cartQuantity : false;

  const hide = useCallback(() => {
    if (isVisible) {
      setVisible(false);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    }
  }, [isVisible, setVisible]);

  useEffect(() => {
    if (remaining !== nthQuantity && !isPromoReachable) {
      hide();
    }
  }, [isPromoReachable, hide, remaining, nthQuantity]);

  const triggerVisible = () => {
    if (isAvailable && isPromoReachable) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      setVisible(true);
      timeout.current = window.setTimeout(() => setVisible(false), AUTO_CLOSE_DELAY);
    }
  };

  return { isVisible, triggerVisible, hide };
};
