import Icon from '~/src/common/components/Icon';

import { useMyProductsButton } from '../../hooks/use-my-products-button';

import * as S from './layout';

type Props = {
  product: { id: string; sku: string; name: string };
};

const MyProductsCardButton = ({ product }: Props) => {
  const button = useMyProductsButton(product);

  return button ? (
    <S.CardButtonContainer onClick={button.handleClick} title={button.label}>
      <Icon name={button.icon} />
    </S.CardButtonContainer>
  ) : null;
};

export default MyProductsCardButton;
