import React, { SyntheticEvent } from 'react';

import StyledPrice from '~/src/common/components/GridCard/ProductCard/AvailableProductCard/StyledPrice/StyledPrice';
import { getPriceGranularity } from '~/src/common/utils/prices';

import { BottomSectionContainer } from '../../layout';
import { BasicProduct } from '../type';

import * as S from './layout';
import Picker from './Picker';

interface ActionCardProps {
  product: Pick<
    BasicProduct,
    'itemPrice' | 'itemDefinition' | 'availableQuantity' | 'promo' | 'packSize'
  >;
  isDeliverySetup: boolean;
  onDisabledPickerClick: (quantity: number, increment: number) => void;
  cartQuantity: number;
}

const PickerSection = ({
  product,
  isDeliverySetup,
  cartQuantity,
  onDisabledPickerClick,
}: ActionCardProps) => {
  const { availableQuantity, itemPrice, itemDefinition, promo, packSize } = product;

  const isPromo = promo != null;
  const isBatchDiscount = isPromo && promo.mechanism === 'BATCH_DISCOUNT';
  const price = isBatchDiscount ? promo.itemOriginalPrice * packSize : itemPrice * packSize;

  const priceGranularity = getPriceGranularity(itemDefinition, { displayWeight: true, packSize });

  const stopOnProductCartClickEventPropagation = (e: SyntheticEvent) => {
    // Utilisé pour ne pas jouer le onClick du composant ProductCard
    // TODO refacto le composant pour mieux gérer ça
    //  => si on a un onClose sur la carte produit il ne faudrait pas le jouer lors d'un click sur le picker
    e.stopPropagation();
  };

  return (
    <BottomSectionContainer onClick={stopOnProductCartClickEventPropagation}>
      <S.BottomSectionInformationContainer>
        <StyledPrice
          price={price}
          promo={
            promo ? { ...promo, itemOriginalPrice: promo.itemOriginalPrice * packSize } : undefined
          }
        />
        {priceGranularity ? (
          <S.ProductPriceGranularity>{priceGranularity.toLowerCase()}</S.ProductPriceGranularity>
        ) : null}
      </S.BottomSectionInformationContainer>
      <Picker
        availableQuantity={availableQuantity}
        onDisabledClick={onDisabledPickerClick}
        cachedQuantity={cartQuantity}
        enabled={isDeliverySetup}
        packSize={packSize}
      />
    </BottomSectionContainer>
  );
};

export default PickerSection;
