import styled from 'styled-components';

import { transparentize } from '~/src/common/utils/style';

export const DeliveryInfoLayout = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ $isOpen }) => ($isOpen ? '100%' : 0)};

  z-index: 10;
  border-radius: 8px;
  overflow: hidden;

  background-color: ${({ theme, $isOpen }) =>
    $isOpen ? transparentize(theme.palette.common.BLACK_PURE, 0.4) : 'transparent'};
  transition:
    background-color 0.15s,
    height 0.15s;
`;

export const DeliveryInfoContainer = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  padding: 8px 8px 16px;

  background-color: ${({ theme }) => theme.palette.common.PRIMARY};
  color: ${({ theme }) => theme.palette.common.WHITE};

  height: ${({ $isOpen }) => ($isOpen ? '100%' : 0)};
  transition: max-height 0.15s ease;
`;

export const DeliveryInfoContent = styled.div`
  display: flex;
  height: 100%;
`;
